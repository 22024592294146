

























































import TextInput from '@/components/shared/forms/TextInput.vue';
import SubmitButton from '@/components/shared/SubmitButton.vue';
import { VendorSettingsPage } from '@/model/vendor-settings-page';
import Vue from 'vue';
import { mapStores } from 'pinia';
import { useVendorsStore } from '@/store/vendors-store';
import { useCountriesStore } from '@/store/countries-store';
import { VendorFormData } from '@/model/vendor-form-data';

export default Vue.extend({
  name: 'VendorForm',
  components: {
    SubmitButton,
    TextInput
  },
  data() {
    return {
      key: 0
    };
  },
  computed: {
    ...mapStores(useVendorsStore, useCountriesStore),
    sendingData(): boolean {
      return this.vendorsStore.saving;
    },
    vendor(): VendorFormData {
      return this.vendorsStore.form;
    },
    errors(): {} {
      return this.vendorsStore.validationErrors;
    }
  },
  created() {
    this.countriesStore.findAll();
  },
  methods: {
    async onSubmit(form) {
      form.validate();
      if (form.valid) {
        await this.vendorsStore.edit(this.vendor, VendorSettingsPage.FIDELITY);
        this.key++;
      }
    }
  }
});
