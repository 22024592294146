var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(form){return [_c('form',{staticClass:"row",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit(form)}}},[_c('h6',{staticClass:"font-weight-bold col-12"},[_vm._v(" "+_vm._s(_vm.$t('vendorSignUp.generalSettings.fidelity'))+" ")]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"alert-light mb-4 rounded p-3"},[_c('div',{staticClass:"small text-dark"},[_vm._v(" "+_vm._s(_vm.$t('vendorSignUp.generalSettings.fidelityInformation'))+" ")])])]),_c('text-input',{ref:"points_per_chf_conversion",staticClass:"col-md-6",attrs:{"value":_vm.vendor.points_per_chf_conversion,"errors":_vm.errors,"rules":{
        numeric: true,
        otherFieldSet: '@number_of_visit_for_voucher'
      },"name":"points_per_chf_conversion","label":_vm.$t('label.pointsPerChfConversion'),"help-text":_vm.$t('helpText.pointsPerChfConversion'),"append-text":_vm.$t('label.append.points')},on:{"onValueChange":function (value) {
          _vm.vendor.points_per_chf_conversion = value;
        }}}),_c('text-input',{staticClass:"col-md-6",attrs:{"value":_vm.vendor.number_of_visit_for_voucher,"errors":_vm.errors,"rules":{ numeric: true, otherFieldSet: '@points_per_chf_conversion' },"name":"number_of_visit_for_voucher","label":_vm.$t('label.numberOfVisitForVoucher'),"help-text":_vm.$t('helpText.numberOfVisitForVoucher'),"append-text":_vm.$t('label.append.visits')},on:{"onValueChange":function (value) {
          _vm.vendor.number_of_visit_for_voucher = value;
        }}}),_c('submit-button',{staticClass:"col-12",attrs:{"loading":_vm.sendingData,"label":"Enregistrer"}})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }